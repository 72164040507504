




import { Inject } from 'inversify-props';
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import USER_LEVELS from '@/modules/user/constants/user-levels.constant';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import SCAN_STATUS from '@/modules/markets/constants/scan-status.constant';

@Component
export default class HotelTransitionPage extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(AuthServiceS) private authService!: AuthService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesServiceS) private ratesService!: RatesService;

    @Prop({
        required: true,
        type: Number,
    })
    hotelId!: number;

    private scanUpdateInterval: NodeJS.Timeout | null = null;
    private MAX_CHECK_REQUESTS = 20;

    @Watch('hotelId', { immediate: true })
    async initCurrentHotel(hotelId: number): Promise<void> {
        if (!this.userService.storeState.user) {
            return;
        }

        const currentHotelId = hotelId || this.userService.storeState.user.currentHotelId;

        if (currentHotelId && this.$route.name) {
            this.ratesAnalysisFiltersService.resetComparisonFilters();
            const oldHotelId = this.userService.currentHotelId;
            this.userService.currentHotelId = currentHotelId;
            await this.userService.switchCurrentHotel(currentHotelId);

            if (oldHotelId !== currentHotelId) {
                this.authService.socketHandshake();
            }
        }

        const routeBranch = window.location.pathname.split('/')[1];
        const isHotelLevel = routeBranch === 'hotel';
        const isHotelSettings = routeBranch === 'settings' && !!hotelId;

        this.ratesFiltersService.competitors = [];
        this.ratesFiltersService.setDefaultCompetitors();

        if (this.$route.query.provider) {
            this.ratesFiltersService.storeState.settings.provider = this.$route.query.provider as string;
        }

        if (isHotelLevel || isHotelSettings) {
            this.userService.setViewAs(USER_LEVELS.HOTEL, hotelId);
        }
    }

    // Temporary solution, to check scan status and reset rates document
    @Watch('ratesService.data')
    resetRatesDocument() {
        if (this.scanUpdateInterval !== null || this.ratesService.data === null) {
            return;
        }

        if (this.ratesService.data.scanStatus !== SCAN_STATUS.IN_PROGRESS) {
            if (this.scanUpdateInterval !== null) {
                clearInterval(this.scanUpdateInterval);
                this.scanUpdateInterval = null;
            }

            return;
        }

        const checkScanStatusInterval = 60000;
        let checkRequestCounter = 0;

        this.scanUpdateInterval = setInterval(async () => {
            if (checkRequestCounter > this.MAX_CHECK_REQUESTS) {
                clearInterval(this.scanUpdateInterval!);
                this.scanUpdateInterval = null;
            }

            if (this.scanUpdateInterval === null) {
                return;
            }

            if (
                this.ratesService.data === null || this.ratesService.data.scanStatus !== SCAN_STATUS.IN_PROGRESS
            ) {
                clearInterval(this.scanUpdateInterval);
                this.scanUpdateInterval = null;
            }

            if (this.ratesService.data && this.ratesService.data.scanStatus === SCAN_STATUS.IN_PROGRESS) {
                const scanStatus = await this.ratesService.checkScanStatus();
                checkRequestCounter += 1;

                if (scanStatus !== SCAN_STATUS.IN_PROGRESS && this.ratesService.data.scanStatus === SCAN_STATUS.IN_PROGRESS) {
                    this.ratesService.storeState.loading.reset();
                    clearInterval(this.scanUpdateInterval!);
                    this.scanUpdateInterval = null;
                }
            }
        }, checkScanStatusInterval);
    }

    beforeDestroy() {
        // Resets all rates filters after leave hotel rates page
        this.ratesFiltersService.competitors = [];
        this.ratesFiltersService.resetRatesFilters();

        // Temporary solution, to check scan status and reset rates document
        if (this.scanUpdateInterval !== null) {
            clearInterval(this.scanUpdateInterval);
            this.scanUpdateInterval = null;
        }
    }
}
